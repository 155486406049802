import App from "./App.vue";

import { createApp } from "vue";
import BootstrapVueNext from "bootstrap-vue-next";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
  faBarcode,
  faStarOfLife,
  faForward,
  faSpinner,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

// Optional, since every component imports their Bootstrap functionality
// the following line is not necessary
// import 'bootstrap'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "./registerServiceWorker";

import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

/* add icons to the library */
library.add(faBarcode, faStarOfLife, faForward, faSpinner, faUser);

const app = createApp(App);
app.use(BootstrapVueNext);
app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: POSITION.TOP_CENTER,
  timeout: 1600,
});
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
